import React from 'react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import Plan from '../components/Plan';
import { useQuery } from 'react-query'
import axios from 'axios';
import { getTranslate, lang } from "../localization/index"
import useToken from '../hooks/useToken';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '78px !important',
        flexDirection: 'column !important',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            padding: '78px 10px !important',
        },
    },
    main: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
}))
export default function Plans() {
    const classes = useStyles()
    const { token } = useToken();
    const translate = getTranslate()
    const getServices = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/services?lang=${lang}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(async (res) => {
                return res.data
            })
        return data
    }
    const { data, isLoading } = useQuery(`getPlans`, () => { return getServices() })
    return (
        <Grid className={classes.root} container lg={12} id="plans">
            <Typography variant='h6'>{translate.ourPlans}</Typography>
            <Typography variant='h3' style={{ color: '#000' }}>
                {translate.ourPlansSubTitle}
            </Typography>
            <Grid className={classes.main} container>
                {data?.map(service => {
                    return <Plan id={service.id} isPopular={service.isPopular} title={service.name} desc={service.description} price={service.price} options={service.options} />
                })}
            </Grid>
        </Grid>
    )
}
