import React from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '100%',
        flex: 1,
        marginTop: 40,
        flexDirection: 'column !important',
        marginRight: 50,
        boxShadow: '0px 4px 13px 1px rgba(128, 128, 128, .1)',
        marginBottom: 40,
        padding: "60px 30px 60px 30px",
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            padding: '30px 15px'
        },
    }
}))
export default function MyRequest({ serviceName, fullName, id }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const translate = getTranslate()
    return (
        <Grid className={classes.root} lg={3} md={5} xs={12}>
            <Typography variant='h4' style={{ textAlign: 'center' }}>{serviceName}</Typography>
            <Typography variant='body2' style={{ textAlign: 'center' }}>Requested for {fullName}</Typography>
            <Button
                style={{ marginTop: 30 }}
                variant='contained'
                onClick={() => navigate(`/my-request/${id}`)}
            >
                <Typography variant='body1' >{translate.moreDetail}</Typography>
            </Button>
        </Grid>
    )
}
