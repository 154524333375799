import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button } from '@mui/material'
import PlanPoint from './PlanPoint';
import tickIcon from '../assets/images/icons8-tick.svg'
import wrongIcon from '../assets/images/icons8-wrong.svg'
import euroIcon from '../assets/images/icons8-euro-32.png'
import { useNavigate } from "react-router-dom";
import UserContext from '../context/UserContext';
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '100%',
        flex: 1,
        marginTop: 40,
        flexDirection: 'column !important',
        marginRight: 50,
        boxShadow: '0px 4px 13px 1px rgba(128, 128, 128, .1)',
        marginBottom: 40,
        padding: "60px 30px 60px 30px",
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            padding: '30px 15px'
        },
    },
    title: {
        textAlign: 'left !important',
        marginBottom: 10
    },
    desc: {
        textAlign: 'left !important'
    }
}))
export default function Plan({ id, title, desc, price, isPopular, options }) {
    const classes = useStyles()
    const navigate = useNavigate();
    const translate = getTranslate()
    const { user } = useContext(UserContext);
    const handleClick = () => {
        if (user) {
            navigate(`/services/${id}`)
        }
        else {
            navigate('/login')
        }
    }
    return (
        //TODO: handle isPopular
        <Grid className={classes.root} lg={3} md={5} xs={12}>
            <div style={{ paddingBottom: 5, width: '100%', position: 'relative' }}>
                {
                    isPopular ? (
                        <Typography variant='h4' color="primary" style={{ textAlign: 'left', position: 'absolute', top: -40 }}>*{translate.popular}</Typography>
                    ) : null
                }
                <Typography variant='subtitle1' className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.desc} variant='body2' >{desc}</Typography>
            </div>
            <div style={{ paddingTop: 5, width: '100%', paddingLeft: 10 }}>
                {options.map(option => {
                    return <PlanPoint title={option.title} icon={option.type ? tickIcon : wrongIcon} />
                })}
            </div>
            <div style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'row-reverse', justifyContent: 'start' }}>
                <Typography variant='h3' style={{ color: '#000' }}>{price}</Typography>
                <img src={euroIcon} style={{ width: 30, height: 30, marginRight: 5 }} />
            </div>
            <Button
                variant='contained'
                size='large'
                style={{ alignSelf: 'flex-start', marginTop: 15 }}
                onClick={() => handleClick()}
            >
                <Typography variant='body1'>{translate.orderNow}</Typography>
            </Button>
        </Grid>
    )
}
