import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography, TextField, Hidden } from '@mui/material'
import Button from '@mui/lab/LoadingButton'
import image from '../assets/images/boy-with-rocket-light.png'
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import useToken from '../hooks/useToken';
import UserContext from '../context/UserContext';
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100vh'
    },
    imageGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e3f2fd',
        maxHeight: '100vh'
    },
    formGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '100vh',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: '0px 15px'
        },
    },
    formWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column !important',
        alignItems: 'start'
    },
    inputsWrapper: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    description: {
        width: '90%',
        textAlign: 'left'
    }
}))
export default function Signup() {
    const classes = useStyles()
    const translate = getTranslate()
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPass, setConfirmPass] = useState();
    const [loading, setLoading] = useState()
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [code, setCode] = useState("")
    const { user } = useContext(UserContext);
    const { setToken } = useToken();
    const handleClick = () => {
        navigate('/login')
    }
    if (user) {
        navigate('/')
    }
    const signup = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, data)
        },
        {
            onSuccess: (data) => {
                setLoading(false)
                setIsCodeSent(true)
                toast.info("The verification code sent!", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const verify = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-email`, data)
        },
        {
            onSuccess: (data) => {
                setLoading(false)
                toast.success("Success", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                setToken(data.data.access_token)
                navigate('/')
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const handleSignup = () => {
        setLoading(true)
        const data = { email, password, confirmPassword: confirmPass }
        signup.mutate(data)
    }
    const handleVerify = () => {
        setLoading(true)
        const data = { email, code }
        verify.mutate(data)
    }
    const handleKeyDown = (event, type) => {
        if (event.key === 'Enter') {
            if (type === 'verify') {
                handleVerify();
                return
            }
            handleSignup()
        }
    }
    return (
        <Grid className={classes.root} container>
            <ToastContainer />
            <Hidden mdDown>
                <Grid className={classes.imageGrid} lg={8}>
                    <img src={image} style={{ height: '80%' }} />
                </Grid>
            </Hidden>
            <Grid className={classes.formGrid} lg={4}>
                <Grid lg={7} className={classes.formWrapper}>
                    <Typography variant='subtitle1' color="primary" style={{ textAlign: 'left' }} >Easy Nif</Typography>
                    <Typography variant='h4' style={{ textAlign: 'left', color: '#000', paddingTop: 30, paddingBottom: 5 }}>{translate.welcome}</Typography>
                    {isCodeSent ? (
                        <>
                            <Typography variant='h6' color="GrayText" style={{ width: '90%' }}>{translate.enterCode}</Typography>
                            <div className={classes.inputsWrapper} id="code">
                                <TextField
                                    color="info"
                                    size='medium'
                                    style={{ width: '100%' }}
                                    id="verification"
                                    label={translate.code}
                                    value={code}
                                    variant="outlined"
                                    onChange={(e) => setCode(e.target.value)}
                                    inputProps={{ maxLength: 6 }}
                                    onKeyDown={(event) => handleKeyDown(event, 'verify')}
                                />
                            </div>
                            <Button
                                variant='contained'
                                style={{ marginTop: 30, }}
                                size='large'
                                onClick={() => handleVerify()}
                                loading={loading}
                            >
                                <Typography variant='body1'>{translate.verify}</Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography variant='h6' color="GrayText" className={classes.description} >{translate.singInDescription}</Typography>
                            <div className={classes.inputsWrapper} id="sign-up">
                                <TextField
                                    color="info"
                                    size='medium'
                                    style={{ width: '100%' }}
                                    id="email"
                                    label={translate.email}
                                    value={email}
                                    variant="outlined"
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event)}
                                />
                                <TextField
                                    size='medium'
                                    style={{ width: '100%', marginTop: 20 }}
                                    type="password"
                                    id="password"
                                    label={translate.password}
                                    variant="outlined"
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event)}
                                />
                                <TextField
                                    size='medium'
                                    style={{ width: '100%', marginTop: 20 }}
                                    type="password"
                                    id="confirm-pass"
                                    label={translate.confirmPassword}
                                    variant="outlined"
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event)}
                                />
                            </div>
                            <Button
                                variant='contained'
                                style={{ marginTop: 30, }}
                                size='large'
                                onClick={() => handleSignup()}
                                loading={loading}
                                disabled={password !== confirmPass}
                            >
                                <Typography variant='body1'>{translate.signUp}</Typography>
                            </Button>
                        </>
                    )}
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant='body2' color="GrayText" >{translate.haveAccount}</Typography>
                        <Typography onClick={() => handleClick()} style={{ cursor: 'pointer', paddingLeft: 10 }} variant='body2' color="primary">{translate.letsLogin}</Typography>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Typography onClick={() => navigate('/')} style={{ cursor: 'pointer', }} variant='body2' color="primary">{translate.returnHome}</Typography>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
