import React from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material'
import ssl from "../assets/images/ssl.avif"
import aima from "../assets/images/aima_horizontal_cor-300x-q65.png"
import fando from '../assets/images/logo_ue_fundo_soc-300x-q65.png'
import { DisplaySettings, FullscreenExitTwoTone } from '@mui/icons-material';
import ATLogo from '../assets/images/logo-at.png'
import ptGovLogo from '../assets/images/ptgovlogo.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 60,
        flexDirection: 'column !important',
        width: '100%',
        // backgroundColor: "#121c32",
    },
    main: {
        padding: "25px 0",
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderTop: 'solid #ccc 0.5px'
    },
    contactUs: {
        paddingLeft: 80
    },
    imagesWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageWrapper: {
        backgroundColor: "#fff",
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    image: {
        width: 200, padding: 10
    }
}))
export default function Footer() {
    const classes = useStyles()
    return (
        <Grid container lg={12} className={classes.root}>
            <Grid item lg={12} className={classes.main}>
                <Grid container spacing={2} lg={12} className={classes.imagesWrapper}>
                    <Grid onClick={() => window.open("https://eportugal.gov.pt/", "_blank")} xs={12} lg={2} item className={classes.imageWrapper}>
                        <img className={classes.image} src={ptGovLogo} />
                    </Grid>
                    <Grid onClick={() => window.open("https://www.portaldasfinancas.gov.pt/at/html/index.html", "_blank")} xs={12} lg={2} item className={classes.imageWrapper}>
                        <img className={classes.image} src={ATLogo} />
                    </Grid>
                    <Grid onClick={() => window.open("https://transparencyreport.google.com/safe-browsing/search?url=easynif.com", "_blank")} xs={12} lg={2} item className={classes.imageWrapper}>
                        <img className={classes.image} src={ssl} />
                    </Grid>
                    <Grid onClick={() => window.open("https://aima.gov.pt/pt", "_blank")} xs={12} lg={2} item className={classes.imageWrapper}>
                        <img className={classes.image} src={aima} />
                    </Grid>
                    <Grid onClick={() => window.open("https://european-social-fund-plus.ec.europa.eu/", "_blank")} xs={12} lg={2} item className={classes.imageWrapper}>
                        <img className={classes.image} src={fando} />
                    </Grid>
                </Grid>
                {/* <Grid className={classes.contactUs}>
                    <Typography variant='h4' color="white">Contect Us</Typography>
                </Grid> */}
            </Grid>
        </Grid>
    )
}
