import React from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material'
import Header from '../components/Header';
import { useQuery } from 'react-query'
import axios from 'axios'
import useToken from '../hooks/useToken';
import MyRequest from '../components/MyRequest';
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    headerWrapper: {
        backgroundColor: '#fff', width: '100%', height: 84, position: 'fixed', top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        [theme.breakpoints.down('md')]: {
            height: 50
        },
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginTop: '100px !important',
        flexDirection: 'column'
    },
    requestsGrid: {
        width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))
export default function MyRequests() {
    const classes = useStyles()
    const { token } = useToken();
    const translate = getTranslate()
    const getRequests = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/requests`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(async (res) => {
                return res.data
            })
        return data
    }
    const { data: requests, isLoading } = useQuery(`getUserHome`, () => { return getRequests() })
    return (
        <Grid className={classes.wrapper}>
            {/* <Hidden mdDown> */}
            <div className={classes.headerWrapper} >
                <Header />
            </div>
            <Grid lg={10} xs={12} container item className={classes.root}>
                <Typography variant='h4' style={{ alignSelf: 'left' }}>{translate.myRequestSubTitle}</Typography>
                <Grid item className={classes.requestsGrid} lg={10}>
                    {requests && (
                        requests.map(request => {
                            return (
                                <MyRequest id={request.id} serviceName={request.service.name} fullName={request.fullName} />
                            )
                        })
                    )}
                    {isLoading && (
                        <Typography variant='h4' style={{ alignSelf: 'left' }}>Fetching data...</Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}
