import React from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material'
import Image from '../assets/images/aboutUs.png'
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { getTranslate, lang } from '../localization';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 60,
        flexDirection: 'column !important',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            padding: '0px 10px',
        },
    },
    main: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    imageGrid: {
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        alignItems: 'start'
    },
    text: {
        textAlign: 'left !important',
        marginTop: "20px !important"
    },
    fallowUsText: {
        textAlign: "left !important",
        color: "#000"
    },
    iconsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center'
    },
    subTitle: {
        color: '#000', textAlign: 'left !important'
    },
    icon: {
        cursor: "pointer", padding: 5, fontSize: '35px !important', backgroundColor: '#555', margin: "0 5px",
        borderRadius: '50%', color: "#fff", transition: '.5s !important',
        "&:hover": {
            backgroundColor: "#fff",
            color: "#555",
            transition: '.5s'
        }
    },
    hereText: {
        fontWeight: "500", color: "#1976d2", cursor: 'pointer'
    }
}))
const handelOpen = (link) => {
    window.open(link, "_blank")
}
export default function AboutUs() {
    const classes = useStyles()
    const translate = getTranslate()
    const openWhatsApp = () => {
        window.open("https://wa.me/351912088552", "_blank")
    }
    return (
        <Grid className={classes.root} container lg={12}>
            <Typography variant='h6'>{translate.aboutUs}</Typography>
            <Typography variant='h3' style={{ color: '#000' }}>
                {translate.whoAreWe}
            </Typography>
            <Grid className={classes.main} container>
                <Grid item className={classes.imageGrid} lg={6}>
                    <img style={{ width: '100%' }} src={Image} alt='' />
                </Grid>
                <Grid item className={classes.textGrid} lg={6}>
                    <Typography variant='h4' className={classes.subTitle}>
                        {translate.aboutUsSubTitle}
                    </Typography>
                    <Typography className={classes.text} variant='body1'>{translate.aboutUsBody1}</Typography>
                    {
                        lang === 'fa' && (
                            <Typography className={classes.text} variant='body1'>
                                همکاران ما فقط با یک کلیک فاصله دارند.  هر گونه سوالی را در <span variant='body1' className={classes.hereText} onClick={() => openWhatsApp()} >اینجا</span> از ما بپرسید.
                            </Typography>
                        )
                    }
                    {
                        lang === 'en' && (
                            <Typography className={classes.text} variant='body1'>
                                Our collaborators are just a click away. Feel free to ask us any questions <span variant='body1' className={classes.hereText} onClick={() => openWhatsApp()} >here</span>.
                            </Typography>
                        )
                    }
                    {
                        lang === 'pt' && (
                            <Typography className={classes.text} variant='body1'>
                                A nossa equipa está a um clique de distância, para qualquer questão clique <span variant='body1' className={classes.hereText} onClick={() => openWhatsApp()} >aqui</span>.
                            </Typography>
                        )
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                        <EmailIcon style={{ marginRight: 10, cursor: 'pointer' }} />
                        <Typography variant='body1'>info@easynif.com</Typography>
                    </div>
                    <div style={{ marginTop: 20, }}>
                        <Typography className={classes.fallowUsText} variant='h4'>{translate.followUsInSocial}</Typography>
                    </div>
                    <div className={classes.iconsWrapper}>
                        <LinkedInIcon style={{ fontSize: 80 }} className={classes.icon} onClick={() => handelOpen("https://www.linkedin.com/in/easy-nif-721a922b3")} />
                        <InstagramIcon className={classes.icon} onClick={() => handelOpen("https://www.instagram.com/easynif?igsh=MWF5aWo0aTNwOGx2Nw==")} />
                        <YouTubeIcon className={classes.icon} onClick={() => handelOpen("https://youtube.com/@easynif?si=Nt3fQ_4QGtZcZcyQ")} />
                        <XIcon className={classes.icon} onClick={() => handelOpen("https://x.com/easynif?t=8Zpn843EYPK2u_4xCa_dVQ&s=35")} />
                        <WhatsAppIcon className={classes.icon} onClick={() => handelOpen("https://wa.me/351912088552")} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
