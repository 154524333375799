const english = {
    login: "Login",
    home: "Home",
    mainTitle: "Your gateway to Portugal 🇵🇹",
    mainSubTitle: "Our team is ready to support you with all legal services",
    requestTheNif: "Request the Nif NOW",
    seePlansAndPricing: "See plans and pricing",
    welcome: "Welcome to Easy Nif! ",
    loginDesc: "Please sign-in to your account and start the adventure",
    email: "Email",
    password: 'Password',
    confirmPassword: "Repeat Password",
    forgetPassword: "Forget Password?",
    dontHaveAccount: "New on our platform?",
    createAccount: "Create an account",
    returnHome: "Return Home",
    code: "Code",
    enterCode: "Please enter 6-digit code witch sent to your email",
    verify: "Verify",
    singInDescription: "Please create account and start the adventure",
    signUp: "Signup",
    haveAccount: "Have an account?",
    letsLogin: "Let's Login",
    ourPlans: "Our plans",
    ourPlansSubTitle: "Here you can see our plans and price",
    personalData: "Personal data",
    firstName: "First Name",
    lastName: "Last Name",
    save: "Save",
    myRequests: "My Requests",
    profile: 'Profile',
    aboutUs: "About Us",
    whoAreWe: "Who we are?",
    aboutUsBody1: "EasyNIF consists of a 100% online multidisciplinary team dedicated to assisting you in obtaining all the necessary documentation for entry into Portugal",
    aboutUsBody2: "Our collaborators are just a click away. Feel free to ask us any questions here",
    aboutUsSubTitle: "We are here to solve all your immigration concerns",
    followUsInSocial: "Follow us in social media",
    orderNow: "Order Now!",
    fullName: "Full Name",
    whatsAppNo: "Whatsapp Number",
    address: "Full Address",
    passportNo: "Passport Number",
    requestNifFor: "Requesting NIF for?",
    passportIssueDate: "Passport Issue Date",
    passportExpireDate: "Passport Expire Date",
    birthday: "Birthday",
    requestSubmitted: "Your Request submitted successfully",
    next: "Next",
    upload: "Upload",
    back: "Back",
    submitRequest: "Submit request",
    applyForVisa: "Apply for a visa",
    realEstateInvestment: "Real estate investment",
    otherInvestments: "Other investment",
    livingInPortugal: "Living in Portugal",
    other: "Others",
    requestDetail: "More detail",
    moreDetail: "More detail",
    myRequestSubTitle: "Here you can see your requests and theme status",
    resetPasswordSubTitle: "Please enter your email for send reset password email",
    changePassword: "Change password",
    sendEmail: "Send e-mail",
    popular: "Popular",
    logout: "Logout",
    uploadFilesTitle: "Please ensure that you provide the necessary documentation for verification purposes :",
    uploadFileDesc1: "1: a high-quality scan of the first page of your passport",
    uploadFilesDesc2: "2: apostilled or officially translated certificate of address, which can be in English or Portuguese",
    uploadFilesDesc: "Our team will shortly contact you for approval of the documentation.",
    dropzoneText: "Drag and drop here"
}

export default english