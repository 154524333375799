import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography, TextField, Button, Hidden } from '@mui/material'
import Header from '../components/Header';
import axios from 'axios';
import useToken from '../hooks/useToken';
import { useMutation, useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100٪', marginTop: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerWrapper: {
        backgroundColor: '#fff', width: '100%', height: 84, position: 'fixed', top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 13px 1px rgba(128, 128, 128, .1)',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItem: 'flex-start'
    }
}))
export default function Profile() {
    const classes = useStyles()
    const translate = getTranslate()
    const { token } = useToken();
    const [id, setId] = useState();
    const [email, setEmail] = useState('')
    const [firstName, setFirsName] = useState('')
    const [lastName, setLastName] = useState('')
    const [loading, setLoading] = useState();
    const [disabled, setDisable] = useState(true)
    const navigate = useNavigate();
    const getUser = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
            setId(res.data.id)
            setEmail(res.data.email)
            setFirsName(res.data.firstName)
            setLastName(res.data.lastName)
        })
        return data
    }
    const { data, isLoading } = useQuery(['getUserInfo'], async () => await getUser())
    const update = useMutation(
        (data) => {
            return axios.patch(`${process.env.REACT_APP_API_URL}/users`, data, {
                headers: { Authorization: `Bearer ${token}` }
            })
        },
        {
            onSuccess: (res) => {
                setLoading(false)
                toast.success("Your data saved successfully", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
            }
        }
    )
    const handleSave = () => {
        setLoading(true)
        const data = { firstName, lastName }
        update.mutate(data)
    }
    return (
        <div>
            <ToastContainer />
            <div className={classes.headerWrapper} >
                <Header />
            </div>
            <Grid className={classes.root} container>
                <Grid className={classes.form} lg={4} direction='column'>
                    <div style={{ padding: "50px 20px 50px 20px", width: '100%' }} className={classes.wrapper} >
                        <Typography variant='h4' style={{ alignSelf: 'left' }}>{translate.personalData}</Typography>
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 10 }}
                            id="email-profile"
                            label={translate.email}
                            value={email}
                            variant="outlined"
                            disabled
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 20 }}
                            id="firsname-profile"
                            label={translate.firstName}
                            value={firstName}
                            variant="outlined"
                            onChange={(e) => setFirsName(e.target.value)}
                        />
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 20 }}
                            id="lastname-profile"
                            label={translate.lastName}
                            value={lastName}
                            variant="outlined"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <Button
                            variant='contained'
                            style={{ marginTop: 30, }}
                            loading={loading}
                            size='large'
                            onClick={() => handleSave()}
                        >
                            <Typography variant='body1' >{translate.save}</Typography>
                        </Button>
                    </div>
                    <Hidden mdUp>
                        <Button
                            variant='outlined'
                            style={{ marginTop: 15, marginBottom: 15 }}
                            loading={loading}
                            size='large'
                            onClick={() => navigate('/my-requests')}
                        >
                            <Typography variant='body1' >My Requests</Typography>
                        </Button>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    )
}
