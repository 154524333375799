import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography, TextField, Hidden } from '@mui/material'
import image from '../assets/images/boy-with-rocket-light.png'
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Button from '@mui/lab/LoadingButton'
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100vh',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center !important',
            justifyContent: 'center'
        },
    },
    imageGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e3f2fd',
        maxHeight: '100vh'
    },
    formGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '100vh',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: '0px 15px'
        },
    },
    formWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column !important',
        alignItems: 'start',
        maxWidth: '85%'
    },
    inputsWrapper: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}))
export default function ForgetPassword() {
    const classes = useStyles()
    const translate = getTranslate()
    const navigate = useNavigate();
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState()
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [code, setCode] = useState();
    const [verified, setVerified] = useState()
    const [password, setPassword] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const handleClick = () => {
        navigate('/signup')
    }
    const sendOtp = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/password-otp`, data)
        },
        {
            onSuccess: (data) => {
                setLoading(false)
                toast.success("verification code sent", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                setIsCodeSent(true)
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const verify = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-password-otp`, data)
        },
        {
            onSuccess: (data) => {
                setLoading(false)
                toast.success("your email verified", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                setVerified(true)
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const changePassword = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/forget-password`, data)
        },
        {
            onSuccess: (data) => {
                setLoading(false)
                toast.success("your password changed", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                navigate('/login')
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const handleSubmit = () => {
        setLoading(true)
        if (!verified) {
            if (isCodeSent) {
                const data = { email, code }
                verify.mutate(data)
            }
            if (!isCodeSent) {
                const data = { email }
                sendOtp.mutate(data)
            }
        }
        if (verified) {
            const data = { email, password, confirmPassword: confirmPass }
            changePassword.mutate(data)
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }
    return (
        <Grid className={classes.root} container>
            <ToastContainer />
            <Hidden lgDown>
                <Grid className={classes.imageGrid} lg={8}>
                    <img src={image} style={{ height: '80%' }} alt='' />
                </Grid>
            </Hidden>
            <Grid className={classes.formGrid} lg={4}>
                <Grid lg={7} className={classes.formWrapper}>
                    <Typography variant='subtitle1' color="primary" style={{ textAlign: 'left' }} >Easy Nif</Typography>
                    <Typography variant='h4' style={{ textAlign: 'left', color: '#000', paddingTop: 30, paddingBottom: 5 }}>{translate.welcome}</Typography>
                    <Typography variant='h6' color="GrayText" style={{ width: '90%' }}>
                        {isCodeSent ? translate.enterCode : translate.resetPasswordSubTitle}
                    </Typography>
                    <div className={classes.inputsWrapper}>
                        {verified ? (
                            <>
                                <TextField
                                    size='medium'
                                    style={{ width: '100%', marginTop: 20 }}
                                    type="password"
                                    id="password-forget-password"
                                    label={translate.password}
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event)}
                                />
                                <TextField
                                    size='medium'
                                    style={{ width: '100%', marginTop: 20 }}
                                    type="password"
                                    id="confirm-pass-forget-password"
                                    label={translate.confirmPassword}
                                    value={confirmPass}
                                    variant="outlined"
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event)}
                                />
                            </>
                        ) : (
                            <>
                                <TextField
                                    color="info"
                                    size='medium'
                                    style={{ width: '100%' }}
                                    id="email-forget-password"
                                    label={translate.email}
                                    value={email}
                                    variant="outlined"
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={isCodeSent}
                                    onKeyDown={(event) => handleKeyDown(event)}
                                />
                                {
                                    isCodeSent ? (
                                        <TextField
                                            size='medium'
                                            style={{ width: '100%', marginTop: 20 }}
                                            type="password"
                                            id="code-forget-password"
                                            label={translate.code}
                                            value={code}
                                            variant="outlined"
                                            onChange={(e) => setCode(e.target.value)}
                                            onKeyDown={(event) => handleKeyDown(event)}
                                        />
                                    ) : (null)
                                }
                                <Typography onClick={() => navigate('/login')} style={{ paddingTop: 10, cursor: 'pointer' }} variant='h6' color="primary">{translate.returnHome}</Typography>
                            </>
                        )}
                    </div>
                    <Button
                        variant='contained'
                        style={{ marginTop: 30, }}
                        loading={loading}
                        size='large'
                        onClick={() => handleSubmit()}
                    >
                        {verified ? (
                            <Typography variant='body1'>{translate.changePassword}</Typography>
                        ) : (
                            <Typography variant='body1'>{isCodeSent ? translate.verify : translate.sendEmail}</Typography>
                        )}
                    </Button>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant='body2' color="GrayText" >{translate.dontHaveAccount}</Typography>
                        <Typography onClick={() => handleClick()} style={{ cursor: 'pointer', paddingLeft: 10 }} variant='body2' color="primary">{translate.createAccount}</Typography>
                    </div>
                    <Hidden mdUp>
                        <div style={{ marginTop: 10 }}>
                            <Typography onClick={() => navigate('/')} style={{ cursor: 'pointer', }} variant='body2' color="primary">{translate.returnHome}</Typography>
                        </div>
                    </Hidden>
                </Grid>

            </Grid>
        </Grid>
    )
}
