import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography, TextField, Hidden } from '@mui/material'
import image from '../assets/images/boy-with-rocket-light.png'
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Button from '@mui/lab/LoadingButton'
import useToken from '../hooks/useToken';
import UserContext from '../context/UserContext';
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100vh',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center !important',
            justifyContent: 'center'
        },
    },
    imageGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e3f2fd',
        maxHeight: '100vh'
    },
    formGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '100vh',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: '0px 15px'
        },
    },
    formWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column !important',
        alignItems: 'start',
    },
    inputsWrapper: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    textAlign: {
        textAlign: 'left !important'
    },
    forgetPassword: {
        textAlign: 'left !important',
        paddingTop: 10, cursor: 'pointer',
        width: '100%'
    }
}))
export default function Login() {
    const classes = useStyles()
    const navigate = useNavigate();
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState()
    const { user } = useContext(UserContext);
    const translate = getTranslate()
    const handleClick = () => {
        navigate('/signup')
    }
    const { setToken } = useToken();
    if (user) {
        navigate('/')
    }
    const login = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
        },
        {
            onSuccess: (data) => {
                setLoading(false)
                toast.success("Login succeed", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                setToken(data.data.access_token)
                navigate('/')
            },
            onError: (error) => {
                setLoading(false)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const handleLogin = () => {
        setLoading(true)
        const data = { email, password }
        login.mutate(data)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    }
    return (
        <Grid className={classes.root} container>
            <ToastContainer />
            <Hidden mdDown>
                <Grid className={classes.imageGrid} lg={8}>
                    <img src={image} style={{ height: '80%' }} />
                </Grid>
            </Hidden>
            <Grid className={classes.formGrid} lg={4}>
                <Grid lg={7} className={classes.formWrapper}>
                    <Typography className={classes.textAlign} variant='subtitle1' color="primary" >Easy Nif</Typography>
                    <Typography className={classes.textAlign} variant='h4' style={{ color: '#000', paddingTop: 30, paddingBottom: 5 }}>{translate.welcome}</Typography>
                    <Typography className={classes.textAlign} variant='h6' color="GrayText" style={{ width: '90%' }}>{translate.loginDesc}</Typography>
                    <div className={classes.inputsWrapper}>
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%' }}
                            id="email-login"
                            label={translate.email}
                            value={email}
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(event) => handleKeyDown(event)}
                        />
                        <TextField
                            size='medium'
                            style={{ width: '100%', marginTop: 20 }}
                            type="password"
                            id="password-login"
                            label={translate.password}
                            variant="outlined"
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(event) => handleKeyDown(event)}
                        />
                        <Typography onClick={() => navigate('/forget-password')} className={classes.forgetPassword} variant='h6' color="primary">{translate.forgetPassword}</Typography>
                    </div>
                    <Button
                        variant='contained'
                        style={{ marginTop: 30, }}
                        loading={loading}
                        size='large'
                        onClick={() => handleLogin()}
                    >
                        <Typography variant='body1'  >{translate.login}</Typography>
                    </Button>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant='body2' color="GrayText" style={{ fontSize: 14 }} >{translate.dontHaveAccount}</Typography>
                        <Typography onClick={() => handleClick()} style={{ fontSize: 14, cursor: 'pointer', paddingLeft: 10 }} variant='body2' color="primary">{translate.createAccount}</Typography>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Typography onClick={() => navigate('/')} style={{ cursor: 'pointer', }} variant='body2' color="primary">{translate.returnHome}</Typography>
                    </div>
                </Grid>

            </Grid>
        </Grid>
    )
}
