const persian = {
    login: 'ورود',
    home: 'خانه',
    mainTitle: "دروازه ی ورود به پرتغال 🇵🇹",
    mainSubTitle: "تیم ما آماده پشتیبانی از شما برای کلیه خدمات حقوقی است",
    requestTheNif: "درخواست NIF",
    seePlansAndPricing: "مشاهده پلن ها و قیمت",
    welcome: "به Easy Nif خوش آمدید! 👋🏻",
    loginDesc: "لطفا به حساب خود وارد شوید",
    email: "ایمیل",
    password: 'رمز عبور',
    confirmPassword: "تکرار رمز عبور",
    forgetPassword: "فراموشی رمز عبور؟",
    dontHaveAccount: "حساب کاربری ندارید؟",
    createAccount: "ساخت حساب کاربری",
    returnHome: "بازگشت به صفحه اصلی",
    code: "کد تائید",
    enterCode: "کد ۶ رقمی ای که به ایمیل شما ارسال شد را وارد کنید",
    verify: "تائید",
    singInDescription: "حساب کاربری خود را بسازید",
    signUp: "ثبت نام",
    haveAccount: "حساب کاربری دارید؟",
    letsLogin: "وارد حساب شوید",
    ourPlans: "پلن ها",
    ourPlansSubTitle: "پلن ها و قیمت های ما",
    personalData: "اطلاعات شخصی",
    firstName: "نام",
    lastName: "نام خانوادگی",
    save: "ذخیره",
    myRequests: "درخواست های من",
    profile: 'پروفایل',
    aboutUs: "درباره ما",
    whoAreWe: "ما کی هستیم؟",
    aboutUsBody1: "ایزی نیف متشکل از چند تیم حرفه ای و ٪۱۰۰ آنلاین است که به شما در به دست آوردن تمام اسناد لازم برای ورود به کشور  پرتغال کمک می کند",
    aboutUsBody2: "همکاران ما فقط با یک کلیک فاصله دارند.  هر گونه سوالی را در اینجا از ما بپرسید",
    aboutUsSubTitle: "ما اینجا هستیم تا تمام نگرانی های شما را در زمینه مهاجرت حل کنیم",
    followUsInSocial: "ما را در فضای مجازی دنبال کنید",
    orderNow: "الان سفارش دهید",
    fullName: "نام کامل",
    whatsAppNo: "شماره واتساپ",
    address: "ادرس کامل",
    passportNo: "شماره پاسپورت",
    requestNifFor: "درخواست نیف برای؟",
    passportIssueDate: "تاریخ صدور پاسپورت",
    passportExpireDate: "تاریخ انقضای پاسپورت",
    birthday: "تاریخ تولد",
    requestSubmitted: "درخواست شما با موفقیت ثبت شد",
    next: "بعدی",
    upload: "آپلود",
    back: "برگشت",
    submitRequest: "ثبت درخواست",
    applyForVisa: "اپلای برای ویزا",
    realEstateInvestment: "سرمایه گذاری املاک و مستغلات",
    otherInvestments: "سایر سرمایه گذاری ها",
    livingInPortugal: "زندگی در پرتغال",
    other: "بقیه موارد",
    requestDetail: "جزئیات درخواست شما",
    moreDetail: "جزئیات بیشتر",
    myRequestSubTitle: "شما اینجا میتوانید جزئیات درخواست ها خود را مشاهده کنید",
    resetPasswordSubTitle: "لطفا ایمیل خود را وارد کنید",
    changePassword: "تغییر رمزعبور",
    sendEmail: "ارسال ایمیل",
    popular: "محبوب",
    logout: "خروج",
    uploadFilesTitle: "لطفاً اطمینان حاصل کنید که اسناد لازم را به درستی ارائه می کنید:",
    uploadFileDesc1: "1: اسکن با کیفیت بالا از صفحه اول پاسپورت شما",
    uploadFilesDesc2: "2: گواهی نامه یا ترجمه رسمی آدرس که می تواند به زبان انگلیسی یا پرتغالی باشد",
    uploadFilesDesc: "تیم ما به زودی برای تایید مستندات با شما تماس خواهد گرفت.",
    dropzoneText: "اینجا بکشید و رها کنید"
}
export default persian