import React from 'react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        transition: '.1s !important',
        borderBottomColor: '#fff',
        borderBottomWidth: 6,
        borderBottom: 'solid',
        cursor: 'pointer',
        "&:hover": {
            borderBottomColor: '#1976d2',
            borderBottomWidth: 6,
            borderBottom: 'solid',
            transition: '.1s'
        }
    }
}))

export default function HeaderButtons({ title, onClick }) {
    const classes = useStyles()
    return (
        <Grid className={classes.root}>
            <Typography onClick={onClick} style={{ padding: 20 }} variant='h4'>{title}</Typography>
        </Grid>
    )
}
