import React from "react";
import theme from "./themes/theme";
import {
  ThemeProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IndexScreen from "./pages/Index";
import { create } from "jss";
import rtl from "jss-rtl";
import { getDirections } from "./localization/index";
import { StylesProvider, jssPreset } from '@mui/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserProvider } from "./context/UserContext";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  console.log(getDirections())
  return getDirections() === "rtl" ? (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <CacheProvider value={cacheRtl}>
          <CssBaseline />
          <QueryClientProvider client={client} >
            <UserProvider>
              <IndexScreen />
            </UserProvider>
          </QueryClientProvider>
        </CacheProvider>
      </StylesProvider>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <CssBaseline />
        <QueryClientProvider client={client} >
          <UserProvider>
            <IndexScreen />
          </UserProvider>
        </QueryClientProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
