import React from 'react'
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material'
import Header from '../components/Header';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import NifRequestForm from '../components/NifRequestForm';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100٪', marginTop: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerWrapper: {
        backgroundColor: '#fff', width: '100%', height: 84, position: 'fixed', top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 13px 1px rgba(128, 128, 128, .1)',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItem: 'flex-start'
    },
    datePicker: {
        marginTop: '10px !important', width: '100%',
    }
}))
export default function SubmitRequest() {
    const classes = useStyles()
    return (
        <div> 
            <ToastContainer />
            <div className={classes.headerWrapper} >
                <Header />
            </div>
            <Grid className={classes.root} container>
                <NifRequestForm />
            </Grid>
        </div >
    )
}
