const portuguese = {
    home: "Home", mainTitle: "A tua porta de entrada em Portugal 🇵🇹",
    mainSubTitle: "A nossa equipa está preparada o ajudar com todos os serviços legais", requestTheNif: "Peça o NIF AGORA",
    seePlansAndPricing: "Veja os preços & planos", welcome: "Bem-vindo ao EasyNIF! ",
    login: "login",
    loginDesc: "Acede à tua conta e inicia esta Aventura", email: "Email",
    password: 'Password', confirmPassword: "Confirme a Password",
    forgetPassword: "?Esqueceu-se da Password?", dontHaveAccount: "Novo no EasyNIF?",
    createAccount: "registe-se", returnHome: "Home",
    code: "Código", enterCode: "Insira o código de 6 dígitos enviados para o seu email",
    verify: "Verificado", singInDescription: "Crie uma conta e inicie a Aventura",
    signUp: "Signup", haveAccount: "Já tem uma conta?",
    letsLogin: "Faça o login", ourPlans: "Planos",
    ourPlansSubTitle: "Consulte os nossos planos e preços", personalData: "Dados Pessoais",
    firstName: "Primeiro Nome", lastName: "Apelido",
    save: "Guardar", myRequests: "Os meus pedidos",
    profile: 'perfil', aboutUs: "Sobre nós",
    whoAreWe: "Quem somos nós?", aboutUsBody1: "A EasyNIF é formada por uma equipa multidisciplinar 100% online dedicada a ajudá-lo a obter toda a documentação necessária para entrar em Portugal",
    aboutUsBody2: "A nossa equipa está a um clique de distância, para qualquer questão clique aqui ", aboutUsSubTitle: "Estamos aqui para resolver as suas preocupações",
    followUsInSocial: "Siga-nos nas redes sociais", orderNow: "Peça agora",
    fullName: "Nome completo", whatsAppNo: "Número de Whatsapp",
    address: "Morada Completa", passportNo: "Número de Passporte",
    requestNifFor: "Pedido de NIF para", passportIssueDate: "Data de emissão do Passaporte",
    passportExpireDate: "Data de Validade do Passaporte", birthday: "Data de Nascimento",
    requestSubmitted: "O seu pedido foi submetido com sucesso", next: "Seguinte",
    upload: "Carregar", back: "Anterior",
    submitRequest: "Submeter pedido", applyForVisa: "Solicitar um visto",
    realEstateInvestment: "Investimento Imobiliário", otherInvestments: "Outros Investimentos",
    livingInPortugal: "Viver em Portugal", other: "Outros",
    requestDetail: "Mais infrmações", moreDetail: "Mais informações",
    myRequestSubTitle: "Consulte o estado do seu processo", resetPasswordSubTitle: "Por favor insira o seu email",
    changePassword: "Mudar a password", sendEmail: "Enviar um email",
    popular: "Popular",
    logout: 'Sair',
    uploadFilesTitle: "Por favor, certifique- se de fornecer a documentação necessária para fins de verificação: ",
    uploadFileDesc1: "1: uma digitalização de alta qualidade da primeira página do seu passaporte",
    uploadFilesDesc2: "2: certificado de endereço apostilado ou traduzido oficialmente, que pode ser em inglês ou português",
    uploadFilesDesc: "Em breve nossa equipe entrará em contato com você para aprovação da documentação.",
    dropzoneText: "Arraste e solte aqui"
}

export default portuguese