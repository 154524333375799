import React, { useContext, useState } from 'react'
import { Grid, Typography, Button, Hidden } from '@mui/material'
import { makeStyles } from '@mui/styles';
import logo from '../assets/images/logo.png'
import HeaderButtons from './HeaderButtons';
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from '../context/UserContext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { changeLanguage, getTranslate, lang } from '../localization';
import iranIcon from '../assets/images/iran.png'
import usaIcon from '../assets/images/usa.png'
import portugalIcon from '../assets/images/portugal.png'
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useToken from '../hooks/useToken';
import LogoutIcon from '@mui/icons-material/Logout';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: 80,
        justifyContent: 'space-between',
        width: '100%',
        top: 0,
        position: 'fixed',
        backgroundColor: '#fff',
        zIndex: 100,
        [theme.breakpoints.down('md')]: {
            height: 50,
            padding: '0px 10px'
        },
    },
    imageGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        height: '70%', marginTop: 10, alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
            height: '70%',
            marginTop: 0
        },
    },
    changeLangButton: {
        marginRight: '10px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flag: {
        height: 30,
        alignSelf: 'center'
    },
    logoText: {
        fontWeight: '600 !Important',
        marginTop: '15px !important',
        [theme.breakpoints.down('md')]: {
            marginTop: '0px !important',
            fontSize: '20px !important'
        },
    },
    profileMenuIcons: {
        marginRight: 5
    }
}))
export default function Header() {
    const classes = useStyles();
    const navigate = useNavigate();
    const translate = getTranslate()
    const { logout } = useToken()
    const handleClick = () => {
        navigate('/login')
    }
    const location = useLocation();
    const { isUserDataComplete, user } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElProfie, setAnchorElProfile] = useState()
    const open = Boolean(anchorEl);
    const profileOpen = Boolean(anchorElProfie)
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileOpen = (event) => {
        setAnchorElProfile(event.currentTarget)
    }
    let currentLang;
    if (lang === 'fa') {
        currentLang = 'ir'
    } else if (lang === 'pt') {
        currentLang = 'pt'
    } else {
        currentLang = 'en'
    }
    return (
        <Grid className={classes.root} lg={10} item container>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {lang !== 'fa' && (
                    <MenuItem onClick={() => {
                        changeLanguage('fa')
                        setAnchorEl(null);
                    }}><img className={classes.flag} src={iranIcon} /> Persian</MenuItem>
                )}
                {lang !== 'pt' && (
                    <MenuItem onClick={() => {
                        changeLanguage('pt')
                        setAnchorEl(null);
                    }}>
                        <img className={classes.flag} src={portugalIcon} /> Portuguese
                    </MenuItem>
                )}
                {lang !== 'en' && (
                    <MenuItem onClick={() => {
                        changeLanguage('en')
                        setAnchorEl(null);
                    }}>
                        <img className={classes.flag} src={usaIcon} /> English
                    </MenuItem>
                )}
            </Menu>
            <Menu
                id="profile-menu"
                aria-labelledby="profile"
                anchorEl={anchorElProfie}
                open={profileOpen}
                onClose={() => setAnchorElProfile(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    navigate('/profile')
                    setAnchorElProfile(null);
                }}>
                    <PersonIcon color='primary' className={classes.profileMenuIcons} />
                    {translate.profile}
                </MenuItem>
                <MenuItem onClick={() => { logout() }}>
                    <LogoutIcon color='primary' className={classes.profileMenuIcons} />
                    {translate.logout}</MenuItem>
            </Menu>
            <div style={{
                display: 'flex', flexDirection: 'row', height: '100%',
                justifyContent: 'center', alignItems: 'center'
            }}>
                <img src={logo} className={classes.logo} alt="" />
                <Typography className={classes.logoText} variant='h4'>Easy NIF</Typography>
            </div>
            <Hidden mdDown>
                <Grid className={classes.centerGrid}>
                    <HeaderButtons title={translate.home} onClick={() => navigate('/')} />
                    {isUserDataComplete() && user ? (
                        <HeaderButtons title={translate.myRequests} onClick={() => navigate('/my-requests')} />
                    ) : null}
                </Grid>
            </Hidden>
            {user ? (
                <Grid className={classes.rightGrid}>
                    {
                        location.pathname === '/profile' ? (
                            <Button
                                variant='outlined'
                                size='small'
                                onClick={() => navigate('/')}
                            >
                                <Typography variant='button' >{translate.home}</Typography>
                            </Button>
                        ) : (
                            <>
                                <Button variant='outlined' className={classes.changeLangButton} onClick={(e) => handleOpen(e)}>
                                    {lang === 'fa' && (
                                        <img className={classes.flag} src={iranIcon} />
                                    )}
                                    {lang === 'en' && (
                                        <img className={classes.flag} src={usaIcon} />
                                    )}
                                    {lang === 'pt' && (
                                        <img className={classes.flag} src={portugalIcon} />
                                    )}
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={(e) => handleProfileOpen(e)}
                                    style={{ padding: 8 }}
                                >
                                    <PersonIcon />
                                    <ArrowDropDownIcon />
                                </Button>
                            </>
                        )
                    }
                </Grid>
            ) : (
                <Grid className={classes.rightGrid}>
                    <Button variant='outlined' className={classes.changeLangButton} onClick={(e) => handleOpen(e)} >
                        {lang === 'fa' && (
                            <img className={classes.flag} src={iranIcon} />
                        )}
                        {lang === 'en' && (
                            <img className={classes.flag} src={usaIcon} />
                        )}
                        {lang === 'pt' && (
                            <img className={classes.flag} src={portugalIcon} />
                        )}                    </Button>
                    <Button
                        variant='contained'
                    >
                        <Typography variant='body1' onClick={() => handleClick()} >{translate.login}</Typography>
                    </Button>
                </Grid>
            )
            }
        </Grid >
    )
}
