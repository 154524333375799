import { createContext, useState } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState()

    const addUser = (user) => {
        setUser(user)
    };

    const isUserDataComplete = () => {
        return user?.email !== null && user?.firstName !== null && user?.lastName !== null
    }

    return (
        <UserContext.Provider
            value={{ user, addUser, isUserDataComplete }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;
