import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./Home"
import LoginPage from './Login';
import SignupPage from './Signup';
import ProfilePage from './Profile';
import ForgetPasswordPage from './ForgetPassword';
import SubmitRequestPage from './SubmitRequest';
import MyRequestsPage from './MyRequests';
import axios from 'axios'
import useToken from '../hooks/useToken';
import { useQuery } from 'react-query'
import UserContext from '../context/UserContext';
import RequestDetail from './RequestDetail';
export default function Index() {
    const { token } = useToken();
    return (
        <Router>
            <Routes>
                <Route exact index path="/" element={<HomePage />}></Route>
                <Route exact index path="/login" element={<LoginPage />}></Route>
                <Route exact index path="/signup" element={<SignupPage />}></Route>
                <Route exact index path="/profile" element={<ProfilePage />}></Route>
                <Route exact index path="/my-requests" element={<MyRequestsPage />}></Route>
                <Route exact index path="/forget-password" element={<ForgetPasswordPage />}></Route>
                <Route exact index path='/services/:id' element={<SubmitRequestPage />}></Route>
                <Route exact index path='/my-request/:id' element={<RequestDetail />}></Route>
            </Routes>
        </Router>
    )
}
