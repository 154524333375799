import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography, Button } from '@mui/material'
import image from '../assets/images/main.png'
import { Link } from 'react-scroll';
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
        overflow: 'hidden',
        position: 'relative',
        '&:before': {
            background: '#121c32',
            position: 'absolute',
            content: '" "',
            aspectRatio: '1/1',
            borderRadius: '100%',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '170%',
            zIndex: - 1,
            height: '165%',
            [theme.breakpoints.down('md')]: {
                width: '214%',
                height: '93%'
            },
        },
        [theme.breakpoints.down('md')]: {
            height: '100%',
            paddingTop: 100,
            paddingBottom: 100
        },
    },
    main: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column !important',
            padding: '0px 10px'
        },
        position: 'relative',
        zIndex: 10,
        display: 'flex',
        flexDirection: 'row-reverse !important',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textGrid: {
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        alignItems: 'start'
    },
    button: {
        marginTop: '20px !important', marginLeft: '10px !important',
        [theme.breakpoints.down('md')]: {
            marginLeft: "0 !important"
        },
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column !important',
        },
    }
}))
export default function Main() {
    const classes = useStyles()
    const translate = getTranslate()
    return (
        <Grid className={classes.root} item lg={12}>
            <Grid className={classes.main} item lg={12}>
                <Grid lg={5} xs={12} item>
                    <img src={image} style={{ width: '100%' }} alt="" />
                </Grid>
                <Grid className={classes.textGrid} lg={5} xs={12} item>
                    <Typography variant='h4' color="white">Easy NIF</Typography>
                    <Typography variant='h1'>{translate.mainTitle}</Typography>
                    <Typography variant='body1' style={{ paddingTop: 30, color: '#fff' }}>{translate.mainSubTitle}</Typography>
                    <div className={classes.buttonsWrapper}>
                        <Link activeClass="active" to="plans" spy={true} smooth={true}>
                            <Button
                                style={{ marginTop: 20 }}
                                variant='contained'
                            >
                                <Typography variant='button'>{translate.requestTheNif}</Typography>
                            </Button>
                        </Link>
                        <Link activeClass="active" to="plans" spy={true} smooth={true}>
                            <Button
                                className={classes.button}
                                variant='outlined'
                            >
                                <Typography variant='button'>{translate.seePlansAndPricing}</Typography>
                            </Button>
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
