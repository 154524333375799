import en_us from "./english";
import fa_ir from "./persian";
import pt_pt from "./portuguese"

const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
export { lang };
const directions = {
  fa: "rtl",
  en: "ltr",
  pt: "ltr"
};
function getDirections() {
  return directions[lang];
};
export { getDirections };

const fonts = {
  fa: "IRANSans",
  en: "Whitney",
  pt: "Whitney"
};
function getFonts() {
  return fonts[lang];
};
export { getFonts };

const translates = {
  en: en_us,
  fa: fa_ir,
  pt: pt_pt
};
function getTranslate() {
  return translates[lang];
};
export { getTranslate };

function changeLanguage(newlang) {
  if (newlang === lang) {
    return;
  };
  localStorage.setItem("lang", newlang);
  window.location.reload();
};
export { changeLanguage };