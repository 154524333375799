import React, { useContext } from 'react'
import { Grid, Hidden } from '@mui/material'
import Header from '../components/Header'
import { makeStyles } from '@mui/styles';
import Main from '../sections/Main';
import Plans from '../sections/Plans';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import useToken from '../hooks/useToken';
import { useQuery } from 'react-query'
import UserContext from '../context/UserContext';
import AboutUs from '../sections/AboutUs';
import whatsapp from '../assets/images/icons8-whatsapp-96.svg'
import whatsappSmall from '../assets/images/icons8-whatsapp-48.svg'
import Footer from '../sections/Footer';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    headerWrapper: {
        backgroundColor: '#fff', width: '100%', height: 84, position: 'fixed', top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        [theme.breakpoints.down('md')]: {
            height: 50
        },
    },
    whatsappIcon: {
        position: "fixed",
        bottom: "1%",
        right: '1%',
        cursor: 'pointer'
    },
    '@global': {
        html: {
            WebkitFontSmoothing: 'auto',
        },
        '*::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#bbb',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#1976d2',
            borderRadius: 1000
        },
    },
}))
export default function Home() {
    const classes = useStyles()
    const navigate = useNavigate();
    const { token } = useToken();
    const { addUser, isUserDataComplete } = useContext(UserContext);
    const getUser = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(async (res) => {
                addUser(res.data)
            })
        return data
    }
    const { data } = useQuery(`getUserHomedswee`, () => { return getUser() })
    if (isUserDataComplete() === false)
        navigate('/profile')

    return (
        <Grid className={classes.wrapper}>
            {/* <Hidden mdDown> */}
            <div className={classes.headerWrapper} >
                <Header />
            </div>
            {/* </Hidden> */}
            <Grid lg={10} xs={12} container item className={classes.root}>
                <Main />
                <Plans />
                <AboutUs />
                <Footer />
            </Grid>
            {/* <Hidden mdDown>
                <img className={classes.whatsappIcon} style={{ width: 50 }} onClick={() => window.open("https://wa.me/351912088552", "_blank")} src={whatsapp} alt="" />
            </Hidden>
            <Hidden mdUp style={{ position: 'fixed' }}> */}
            <img className={classes.whatsappIcon} onClick={() => window.open("https://wa.me/351912088552", "_blank")} src={whatsappSmall} alt="" />
            {/* </Hidden> */}
        </Grid>
    )
}
