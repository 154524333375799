import React from 'react'
import { makeStyles } from '@mui/styles';
import Header from '../components/Header';
import { useQuery } from 'react-query'
import axios from 'axios'
import useToken from '../hooks/useToken';
import { useParams } from 'react-router-dom'
import { Grid, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    headerWrapper: {
        backgroundColor: '#fff', width: '100%', height: 84, position: 'fixed', top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        [theme.breakpoints.down('md')]: {
            height: 50
        },
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginTop: '100px !important',
        flexDirection: 'column'
    },
    form: {
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        alignItem: 'flex-start',
        padding: "50px 20px 50px 20px", width: '100%'
    },
    datePicker: {
        marginTop: '10px !important', width: '100%',
    }
}))

export default function RequestDetail() {
    const classes = useStyles()
    const { token } = useToken();
    const translate = getTranslate()
    const { id: requestId } = useParams();
    const getRequests = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(async (res) => {
                return res.data
            })
        return data
    }
    const { data: request, isLoading } = useQuery(`findRequest`, () => { return getRequests() })

    return (
        <Grid className={classes.wrapper}>
            {/* <Hidden mdDown> */}
            <div className={classes.headerWrapper} >
                <Header />
            </div>
            <Grid lg={4} container item className={classes.root}>
                <Typography variant='h4' style={{ alignSelf: 'left' }}>{translate.requestDetail}</Typography>
                <Grid item className={classes.form}>
                    <TextField
                        color="info"
                        size='medium'
                        style={{ width: '100%', marginTop: 20 }}
                        id="fullName-submit-request"
                        label="Full Name"
                        value={request?.fullName ? request.fullName : ''}
                        variant="outlined"
                        required
                    // onChange={(e) => setFullName(e.target.value)}
                    />
                    <TextField
                        color="info"
                        size='medium'
                        style={{ width: '100%', marginTop: 10 }}
                        id="email-submit-request"
                        label="Email"
                        value={request?.email ? request.email : ''}
                        variant="outlined"
                        // onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        color="info"
                        size='medium'
                        style={{ width: '100%', marginTop: 10 }}
                        id="whatsappNo-submit-request"
                        label="Whatsapp Number"
                        value={request?.whatsapp ? request.whatsapp : ''}
                        variant="outlined"
                        // onChange={(e) => setWhatsappNumber(e.target.value)}
                        required
                    />
                    <TextField
                        color="info"
                        size='medium'
                        style={{ width: '100%', marginTop: 20 }}
                        id="address-submit-request"
                        label="Full Address"
                        value={request?.address ? request.address : ''}
                        variant="outlined"
                        // onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                    <TextField
                        color="info"
                        size='medium'
                        style={{ width: '100%', marginTop: 20 }}
                        id="passport-number-submit-request"
                        label="Passport Number"
                        value={request?.passportNumber ? request.passportNumber : ''}
                        variant="outlined"
                        // onChange={(e) => setPassportNumber(e.target.value)}
                        required
                    />
                    <TextField
                        color="info"
                        size='medium'
                        style={{ width: '100%', marginTop: 20 }}
                        id="passport-number-submit-request"
                        label="Requesting NIF for"
                        value={request?.usingNifFor ? request.usingNifFor : ''}
                        variant="outlined"
                        // onChange={(e) => setPassportNumber(e.target.value)}
                        required
                    />
                    <LocalizationProvider style={{ width: '100%', marginTop: 20 }} dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                className={classes.datePicker}
                                label="Passport Issue Date"
                                color="info"
                                value={dayjs(request?.passportIssueDate)}
                                // onChange={(newValue) => setPassportIssueDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                format='YYYY-MM-DD'
                                openTo="year"
                                views={['year', 'month', 'day']}
                                size='medium'
                                disableFuture
                            />
                        </DemoContainer>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                className={classes.datePicker}
                                label="Passport Expire Date"
                                style={{ width: '100%', marginTop: 20 }}
                                color="info"
                                size='medium'
                                value={dayjs(request?.passportExpirationDate)}
                                // onChange={(newValue) => setPassportExpireDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                format='YYYY-MM-DD'
                                openTo="year"
                                views={['year', 'month', 'day']}
                                disablePast
                                required
                            />
                        </DemoContainer>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                className={classes.datePicker}
                                label="birthday"
                                style={{ width: '100%', marginTop: 20 }}
                                color="info"
                                size='medium'
                                value={dayjs(request?.birthday)}
                                // onChange={(newValue) => setBirthday(dayjs(newValue).format("YYYY-MM-DD"))}
                                format='YYYY-MM-DD'
                                openTo="year"
                                views={['year', 'month', 'day']}
                                disableFuture
                                required
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Grid>
    )
}
