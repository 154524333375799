import React from 'react'
import { Grid, Typography } from '@mui/material'
import tickIcon2 from '../assets/images/icons8-wrong.svg'
export default function PlanPoint({ icon, title }) {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img style={{ width: 30, height: 30, marginRight: 5 }} src={icon} />
            <Typography variant='h5'>{title}</Typography>
        </div>
    )
}
