import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import palette from "./pallete";
import { getDirections, getFonts, lang } from "../localization/index";
let theme = createTheme({})
theme = createTheme({
  palette: palette,
  direction: getDirections(),
  spacing: 2.5,
  typography: {
    fontFamily: 'Whitney',
    h1: {
      fontWeight: 600,
      fontSize: lang === 'fa' ? 35 : 50,
      fontFamily: getFonts(),
      textAlign: 'left',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        fontSize: 35,
        fontFamily: getFonts(),
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: 39.12,
      lineHeight: "3.2857rem",
      fontFamily: getFonts(),
      color: '#fff',
      textAlign: 'center'
    },
    h3: {
      fontWeight: 500,
      fontSize: lang === 'fa' ? 35 : 50,
      // lineHeight: "2.7rem",
      fontFamily: getFonts(),
      textAlign: 'left',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        ...theme.typography.h3,
        fontSize: 30,
        textAlign: 'center',
        fontFamily: getFonts(),
      },
    },
    h4: {
      fontWeight: 500,
      fontSize: lang === 'fa' ? 22 : 25.36,
      lineHeight: "2.43rem",
      fontFamily: getFonts(),
      textAlign: 'center'
    },
    h5: {
      fontWeight: 500,
      fontSize: 20.24,
      lineHeight: "1.857rem",
      fontFamily: getFonts(),
      textAlign: 'left',
      // color: '#fff'
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
        fontFamily: getFonts(),
      },
    },
    h6: {
      fontWeight: 200,
      fontSize: 20.4,
      lineHeight: "1.857rem",
      fontFamily: getFonts(),
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 30.36,
      lineHeight: "2.43rem",
      fontFamily: getFonts(),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 26,
        fontFamily: getFonts(),
      },
    },
    subtitle2: {},
    body1: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "1.8rem",
      fontFamily: getFonts(),
      textAlign: 'left',
      letterSpacing: lang === 'fa' ? 0 : 1.2,
      [theme.breakpoints.down('md')]: {
        ...theme.typography.body1,
        fontSize: 18,
        fontFamily: getFonts(),
      },
    },
    body2: {
      fontSize: 16.4,
      fontFamily: getFonts(),
      fontWeight: 400,
      letterSpacing: lang === 'fa' ? 0 : 1,
      textAlign: 'left'
    },
    button: {
      fontSize: 15,
      fontFamily: getFonts(),
      fontWeight: 400,
      textAlign: 'center'
    },
  },
});

// theme.typography.h1 = {
//   [theme.breakpoints.down('md')]: {
//     ...theme.typography.h1,
//     fontSize: 35
//   },
// }
// theme.typography.h3 = {
//   [theme.breakpoints.down('md')]: {
//     ...theme.typography.h3,
//     fontSize: 30,
//     textAlign: 'center'
//   },
// }
// theme.typography.body1 = {
//   [theme.breakpoints.down('md')]: {
//     ...theme.typography.body1,
//     fontSize: 18,
//     textAlign: 'center'
//   },
// }
export default theme;
