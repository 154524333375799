import React, { useState } from 'react'
import { Grid, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import useToken from '../hooks/useToken';
import { useMutation } from 'react-query'
import { makeStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone';
import { useNavigate } from "react-router-dom";
import { getTranslate } from '../localization';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 13px 1px rgba(128, 128, 128, .1)',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItem: 'flex-start',
        padding: "50px 20px 50px 20px", width: '100%'
    },
    datePicker: {
        marginTop: '10px !important', width: '100%',
    }
}))

export default function NifRequestForm() {
    const classes = useStyles()
    const translate = getTranslate()
    const { token } = useToken();
    const [requestId, setRequestId] = useState(null)
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [address, setAddress] = useState('')
    const [whatsAppNumber, setWhatsappNumber] = useState('')
    const [passportNumber, setPassportNumber] = useState('')
    const [passportIssueDate, setPassportIssueDate] = useState();
    const [passportExpireDate, setPassportExpireDate] = useState();
    const [birthday, setBirthday] = useState();
    const [files, setFiles] = useState([])
    const [visaType, setVisatype] = useState('')
    const [usingNifFor, setUsingNifFor] = useState('')
    const [loading, setLoading] = useState();
    const [disabled, setDisable] = useState(true)
    const [step, setStep] = useState(0)
    const { id: serviceId } = useParams();
    const ServiceId = +serviceId
    const navigate = useNavigate();

    const saveData = useMutation(
        (data) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/requests`, data, {
                headers: { Authorization: `Bearer ${token}` }
            })
        },
        {
            onSuccess: (data) => {
                toast.success("Data uploaded successfully", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                setRequestId(data.data.id)
                setStep(1)
            },
            onError: (error) => {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    )
    const uploadFiles = useMutation(
        () => {
            const data = new FormData()
            files.map(async (file, i) => {
                return data.append(`file${i}`, file)
            })
            data.append('requestId', requestId)
            return axios.post(`${process.env.REACT_APP_API_URL}/request-files`, data, {
                headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
            })
        },
        {
            onSuccess: (data) => {
                toast.success("Your request submitted successfully", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
                setStep(2)
            },
            onError: (error) => {
                toast.error("something went wrong!", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    theme: "light",
                });
            }
        }
    )
    const handleSave = () => {
        setLoading(true)
        if (step === 0) {
            const data = {
                email, fullName, whatsapp: whatsAppNumber, usingNifFor, visaType, address, passportNumber, passportIssueDate,
                passportExpirationDate: passportExpireDate, birthday, serviceId: ServiceId
            }
            saveData.mutate(data)
        }
        if (step === 1) {
            uploadFiles.mutate()
        }
    }
    return (
        <Grid className={classes.form} lg={4} direction='column'>
            <ToastContainer />
            <div className={classes.wrapper} >
                {step === 0 && (
                    <>
                        <Typography variant='h4' style={{ alignSelf: 'left' }}>{translate.submitRequest}</Typography>
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 20 }}
                            id="fullName-submit-request"
                            label={translate.fullName}
                            value={fullName}
                            variant="outlined"
                            required
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 10 }}
                            id="email-submit-request"
                            label={translate.email}
                            value={email}
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 10 }}
                            id="whatsappNo-submit-request"
                            label={translate.whatsAppNo}
                            value={whatsAppNumber}
                            variant="outlined"
                            onChange={(e) => setWhatsappNumber(e.target.value)}
                            helperText="Like: +989123456789"
                            required
                        />
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 20 }}
                            id="address-submit-request"
                            label={translate.address}
                            value={address}
                            variant="outlined"
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                        <TextField
                            color="info"
                            size='medium'
                            style={{ width: '100%', marginTop: 20 }}
                            id="passport-number-submit-request"
                            label={translate.passportNo}
                            value={passportNumber}
                            variant="outlined"
                            onChange={(e) => setPassportNumber(e.target.value)}
                            required
                        />
                        {/* <FormControl required style={{ width: '100%', marginTop: 20 }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Visa Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={visaType}
                                label="Visa Type"
                                onChange={(e) => setVisatype(e.target.value)}
                                required
                            >
                                <MenuItem value={"Tourist visa"}>Tourist visa</MenuItem>
                                <MenuItem value={"Study work or technology visa"}>Study, work or technology visa</MenuItem>
                                <MenuItem value={"Startup visa"}>Startup visa</MenuItem>
                                <MenuItem value={"D2 visa"}>D2 visa</MenuItem>
                                <MenuItem value={"D7 visa"}>D7 visa</MenuItem>
                                <MenuItem value={"Golden Visa"}>Golden visa</MenuItem>
                                <MenuItem value={"others"}>others</MenuItem>
                            </Select>
                        </FormControl> */}
                        <FormControl required style={{ width: '100%', marginTop: 20 }} fullWidth>
                            <InputLabel id="demo-simple-select-label">{translate.requestNifFor}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={usingNifFor}
                                label={translate.requestNifFor}
                                onChange={(e) => setUsingNifFor(e.target.value)}
                            >
                                <MenuItem value={"Apply for a visa"}>{translate.applyForVisa}</MenuItem>
                                <MenuItem value={"Real estate investment"}>{translate.realEstateInvestment}</MenuItem>
                                <MenuItem value={"Other investments"}>{translate.otherInvestments}</MenuItem>
                                <MenuItem value={"Living in Portugal"}>{translate.livingInPortugal}</MenuItem>
                                <MenuItem value={"Others"}>{translate.other}</MenuItem>
                            </Select>
                        </FormControl>
                        <LocalizationProvider style={{ width: '100%', marginTop: 20 }} dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    className={classes.datePicker}
                                    label={translate.passportIssueDate}
                                    color="info"
                                    value={passportIssueDate}
                                    onChange={(newValue) => setPassportIssueDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                    format='YYYY-MM-DD'
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    size='medium'
                                    disableFuture
                                />
                            </DemoContainer>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    className={classes.datePicker}
                                    label={translate.passportExpireDate}
                                    style={{ width: '100%', marginTop: 20 }}
                                    color="info"
                                    direction="rtl"
                                    size='medium'
                                    value={passportExpireDate}
                                    onChange={(newValue) => setPassportExpireDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                    format='YYYY-MM-DD'
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    disablePast
                                    required
                                />
                            </DemoContainer>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    className={classes.datePicker}
                                    label={translate.birthday}
                                    style={{ width: '100%', marginTop: 20 }}
                                    color="info"
                                    size='medium'
                                    value={birthday}
                                    onChange={(newValue) => setBirthday(dayjs(newValue).format("YYYY-MM-DD"))}
                                    format='YYYY-MM-DD'
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    disableFuture
                                    required
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </>
                )}
                {step === 1 && (
                    <>
                        <Typography variant='h4' textAlign="left">
                            {translate.uploadFilesTitle}
                        </Typography>
                        <Typography variant='h5' >
                            {translate.uploadFileDesc1}
                        </Typography>
                        <Typography variant='h5' >
                            {translate.uploadFilesDesc2}
                        </Typography>
                        <Typography variant='h5' style={{ paddingTop: 10 }}>
                            {translate.uploadFilesDesc}
                        </Typography>
                        <DropzoneArea
                            acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                            showPreviews={false}
                            maxFileSize={3000000}
                            filesLimit={5}
                            getFileAddedMessage={(FileName) => 'Image uploaded successfully.'}
                            getFileRemovedMessage={(FileName) => 'Image deleted successfully'}
                            dropzoneText={translate.dropzoneText}
                            onChange={async (files) => {
                                setFiles(files)
                            }}
                        />
                    </>
                )}
                {step === 2 && (
                    <Typography variant='h4' style={{ alignSelf: 'left' }}>{translate.requestSubmitted}</Typography>
                )}
                {step !== 2 ? (
                    <>
                        <Button
                            variant='contained'
                            style={{ marginTop: 30, }}
                            loading={loading}
                            size='large'
                            onClick={() => handleSave()}
                        >
                            <Typography variant='body1' >{step === 0 ? translate.next : translate.upload}</Typography>
                        </Button>
                        {step > 0 && (
                            <Button
                                variant='outlined'
                                style={{ marginTop: 30, }}
                                loading={loading}
                                size='large'
                                onClick={() => setStep(step - 1)}
                            >
                                <Typography variant='body1' >{translate.back}</Typography>
                            </Button>
                        )}
                    </>

                ) : (
                    <Button
                        variant='contained'
                        style={{ marginTop: 30, }}
                        loading={loading}
                        size='large'
                        onClick={() => navigate("/")}
                    >
                        <Typography variant='body1' >{translate.returnHome}</Typography>
                    </Button>
                )
                }
            </div>
        </Grid>
    )
}
